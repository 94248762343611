<template>
  <div class="app-container">
    <div class="header">
      <div class="title">飞宇建材瓷砖修复治理登记表</div>
      <button v-if="!isLoggedIn" @click="openLoginModal" class="open-modal-button">登录</button>
    </div>

    <!-- 登录弹出框 -->
    <div v-if="isLoginModalOpen" class="modal">
      <div class="modal-content">
        <span @click="closeLoginModal" class="close-button">&times;</span>
        <h2>用户登录</h2>
        <form @submit.prevent="handleLogin" class="form-container">
          <input type="text" v-model="username" placeholder="手机号" required class="input-field">
          <input type="password" v-model="password" placeholder="密码" required class="input-field">
          <div class="button-group">
            <button type="submit" class="submit-button">登录</button>
          </div>
        </form>
      </div>
    </div>

    <!-- 订单输入弹出框 -->
    <div v-if="isModalOpen" class="modal">
      <div class="modal-content">
        <span @click="closeModal" class="close-button">&times;</span>
        <h2>{{ orderId ? '编辑订单' : '新增订单' }}</h2>
        <form @submit.prevent="saveOrder" class="form-container">
          <input type="text" v-model="orderId" placeholder="订单ID（仅编辑时填写）" readonly class="input-field">
          <input type="date" v-model="orderDate" required class="input-field">
          <input type="text" v-model="customerLocation" placeholder="客户地址" required class="input-field">
          <input type="text" v-model="contactNumber" placeholder="联系电话" required class="input-field">
          <input type="text" v-model="workerName" placeholder="负责人姓名" required class="input-field">
          <input type="text" v-model="materialBrand" placeholder="材料品牌" required class="input-field">
          <input type="number" v-model.number="materialCost" placeholder="材料成本" required class="input-field" step="0.01">
          <input type="text" v-model="afterServiceStatus" placeholder="售后服务状态" required class="input-field">
          <input type="text" v-model="remarks" placeholder="备注（可选）" class="input-field">
          <div class="button-group">
            <button type="submit" class="submit-button">保存订单</button>
            <button type="button" @click="closeModal" class="cancel-button">取消</button>
          </div>
        </form>
      </div>
    </div>

    <!-- 订单列表表格 -->
    <div class="table-container">
      <div class="list-top">
        <div class="xxxx"></div>
        <button @click="handleNewOrder" class="open-modal-button">新增订单</button>
        <div class="xxx"></div>
        <button @click="handleRefresh" class="refresh-button">刷新订单</button>
      </div>
      <table v-if="orderList.length > 0" class="order-table">
        <thead>
          <tr>
            <th>序号</th>
            <th>订单日期</th>
            <th>客户地址</th>
            <th>联系电话</th>
            <th>负责人姓名</th>
            <th>材料品牌</th>
            <th>材料成本</th>
            <th>售后服务状态</th>
            <th>备注</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(order) in orderList" :key="order.id">
            <td>{{ order.id }}</td>
            <td>{{ order.orderDate }}</td>
            <td>{{ order.customerLocation }}</td>
            <td>{{ order.contactNumber }}</td>
            <td>{{ order.workerName }}</td>
            <td>{{ order.materialBrand }}</td>
            <td>{{ order.materialCost }}</td>
            <td>{{ order.afterServiceStatus }}</td>
            <td>{{ order.remarks }}</td>
            <td>
              <button v-if="isLoggedIn" @click="deleteOrder(order.id)" class="action-button">删除</button>
              <button v-if="isLoggedIn" @click="editOrder(order)" class="action-button">编辑</button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>还没有订单。</p>
    </div>
  <a href="https://beian.miit.gov.cn/">豫ICP备2023002261号-1</a>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      orderId: null,
      orderDate: '',
      customerLocation: '',
      contactNumber: '',
      workerName: '',
      materialBrand: '',
      materialCost: null,
      afterServiceStatus: '',
      remarks: '',
      orderList: [],
      isModalOpen: false,
      isLoginModalOpen: false,
      isLoggedIn: false,
      username: '',
      password: '',
    };
  },
  methods: {
    openModal() {
      this.clearForm();
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    openLoginModal() {
      this.isLoginModalOpen = true;
    },
    closeLoginModal() {
      this.isLoginModalOpen = false;
    },
        async handleLogin() {
      try {
        const response = await axios.post('https://live-yang.top:9090/users/login', {
          phone: this.username,
          password: this.password,
        });

        if (response.data.code === 200) {
          this.isLoggedIn = true;
          alert('登录成功！');
          this.closeLoginModal();
          this.username = '';
          this.password = '';
          this.selectAll();
        } else {
          alert('登录失败，请检查手机号和密码。');
        }
      } catch (error) {
        console.error('登录请求出错：', error);
        alert('登录请求失败，请重试。');
      }
    },
        handleNewOrder() {
      if (this.isLoggedIn) {
        this.openModal();
      } else {
        alert('请先登录');
      }
    },
    handleRefresh() {
      if (this.isLoggedIn) {
        this.selectAll();
      } else {
        alert('请先登录');
      }
    },
    async saveOrder() {
      try {
        let response;
        if (this.orderId) {
          // 如果 orderId 存在，则执行更新操作
          response = await axios.put(`https://live-yang.top:9090/fixOrder/update`, {
            id: this.orderId, // 传递订单ID
            orderDate: this.orderDate,
            customerLocation: this.customerLocation,
            contactNumber: this.contactNumber,
            workerName: this.workerName,
            materialBrand: this.materialBrand,
            materialCost: parseFloat(this.materialCost).toFixed(2),
            afterServiceStatus: this.afterServiceStatus,
            remarks: this.remarks || '',
          });
        } else {
          // 否则执行插入操作
          response = await axios.post('https://live-yang.top:9090/fixOrder/insert', {
            orderDate: this.orderDate,
            customerLocation: this.customerLocation,
            contactNumber: this.contactNumber,
            workerName: this.workerName,
            materialBrand: this.materialBrand,
            materialCost: parseFloat(this.materialCost).toFixed(2),
            afterServiceStatus: this.afterServiceStatus,
            remarks: this.remarks || '',
          });
        }
        if (response.data.code === 200) {
          alert('订单保存成功！');
          this.clearForm();
          this.selectAll();
          this.closeModal();
        }
      } catch (error) {
        console.error('保存订单时出错：', error);
      }
    },
    async selectAll() {
      try {
        const response = await axios.get('https://live-yang.top:9090/fixOrder/listAll');
        this.orderList = response.data;
      } catch (error) {
        console.error('获取订单时出错：', error);
      }
    },
    async deleteOrder(id) {
      const confirmDelete = confirm('您确定要删除该订单吗？');
      if (confirmDelete) {
        try {
          await axios.delete(`https://live-yang.top:9090/fixOrder/delete?id=${id}`);
          await this.selectAll(); // 刷新订单列表
        } catch (error) {
          console.error('删除订单时出错：', error);
        }
      }
    },
    editOrder(order) {
      this.orderId = order.id;
      this.orderDate = order.orderDate;
      this.customerLocation = order.customerLocation;
      this.contactNumber = order.contactNumber;
      this.workerName = order.workerName;
      this.materialBrand = order.materialBrand;
      this.materialCost = order.materialCost;
      this.afterServiceStatus = order.afterServiceStatus;
      this.remarks = order.remarks;
      this.isModalOpen = true;
    },
    clearForm() {
      this.orderId = null;
      this.orderDate = '';
      this.customerLocation = '';
      this.contactNumber = '';
      this.workerName = '';
      this.materialBrand = '';
      this.materialCost = null;
      this.afterServiceStatus = '';
      this.remarks = '';
    },
  },
  mounted() {
    this.handleRefresh();
  },
};
</script>

<style scoped>
.xxx{
  width: 10px;
}

.xxxx{
  width: 87%;
}

.title {
 text-align: center;
  width: 95%;
  font-size: 28px; /* 确保字体大小适合 */
  color: #000; /* 设置字体颜色 */
  margin-bottom: 20px; /* 添加下边距 */
  font-weight: 800;
}

.header{
  display: flex;
  height: 50px;
  padding-left: 0px;
}

.app-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
}

.list-top{
  width: 100%;
  display: flex;
  padding-right: 0px;
}

.open-modal-button,
.refresh-button,
.action-button {
  font-size: 16px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.open-modal-button:hover,
.refresh-button:hover,
.action-button:hover {
  background-color: #0056b3;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  width: 500px;
  animation: fadeIn 0.3s;
  position: relative;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.close-button {
  cursor: pointer;
  font-size: 20px;
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 15px;
}

.close-button:hover {
  color: #000;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input-field {
  width: 95%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.input-field:focus {
  border-color: #007bff;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.submit-button {
  background-color: #28a745;
}

.submit-button:hover {
  background-color: #218838;
}

.cancel-button {
  background-color: #dc3545;
}

.cancel-button:hover {
  background-color: #c82333;
}

.table-container {
  margin-top: 20px;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
}

.order-table th,
.order-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.order-table th {
  background-color: #007bff;
  color: white;
}

.order-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.order-table tbody tr:hover {
  background-color: #e0f7fa;
}</style>
