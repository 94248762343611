import Vue from 'vue'
import App from './App.vue'
import HelloWorld from './components/HelloWorld.vue' // 引入 HelloWorld 组件

Vue.config.productionTip = false

// 注册 HelloWorld 组件
Vue.component('HelloWorld', HelloWorld)

new Vue({
  render: h => h(App), // 渲染 App 组件
}).$mount('#app')
