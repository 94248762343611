<template>
  <div class="app">
    <HelloWorld />
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue';

export default {
  data() {
  },
  components: {
    HelloWorld
  },
  methods: {
  }
};
</script>